<template>
  <div id="app" class="management">
    <Nav />
    <div class="mt50">
      <img
        :src="
          require(`@/assets/banner/banner-04${
            $i18n.locale == 'zh' ? 'z' : 'e'
          }.png`)
        "
      />
      <div class="team" v-for="(item, index) in list" :key="index">
        <div class="team-title" v-if="$i18n.locale == 'zh'">
          <div class="team-title-hz1 team-title-p1">
            {{ item.title3 }}
          </div>
        </div>
        <div class="team-title" v-else>
          <p class="team-title-p1 f-14">{{ item.title1 }}</p>
          <div class="team-title-hz team-title-p1">
            {{ item.title2 }}
          </div>
        </div>
        <div class="team-conter flex wrap">
          <div
            class="mt-15 mb-15"
            :style="'width:' + item.width + ';'"
            v-for="(person, index1) in item.personList"
            :key="index1"
          >
            <img class="team-img" :src="person.url" alt="" />
            <p class="color1 f-12 mt-5 team-p1">
              {{ $i18n.locale == "zh" ? person.name : person.enName }}
            </p>
            <!-- <p class="color1 f-10">{{ person.enName }}</p> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      list: [
        {
          title1: "PARTY & ADMINISTRATIVEOFFICE",
          title2: "OFFICE",
          title3: "党政办公室",
          width: "50%",
          personList: [
            // {
            //   url: require("../../../assets/team/te-2.png"),
            //   name: "季明明\n主任(兼)",
            //   enName: "JI MINGMING\n(concurrently)\nDirector",
            // },
            {
              url: require("../../../assets/team/te-18.png"),
              name: "谭苏亚 | 主任助理",
              enName: "TAN SUYA\nAssistant Director",
            },
            {
              url: require("../../../assets/team/te-35.png"),
              name: "张子昂 | 文秘主管 / 采购",
              enName: "ZHANG ZI’ANG\nClerical Supervisors\nAnd Purchaser",
            },
          ],
        },
        {
          title1: "ORCHESTRA MANAGEMENT &",
          title2: " OPERATIONS DEPARTMENT",
          title3: "艺术业务部",
          width: "33%",
          personList: [
            {
              url: require("../../../assets/team/te-3.png"),
              name: "平川\n主任(兼)",
              enName: "PING CHUAN\n(concurrently)\nDirector",
            },
            {
              url: require("../../../assets/team/te-26.png"),
              name: "董逸伦\n常务副主任",
              enName: "DONG YILUN\nExecutive Deputy\nDirector",
            },
            {
              url: require("../../../assets/team/te-6.png"),
              name: "王曦\n副主任(谱务主管)",
              enName: "WANG XI\nDeputy Director\n(Music Library Supervisor)",
            },
            {
              url: require("../../../assets/team/te-8.png"),
              name: "蒋宁宁\n谱务",
              enName: "JIANG NINGNING\nMusic Librarian",
            },
            {
              url: require("../../../assets/team/te-5.png"),
              name: "郭蔚\n宣传主管",
              enName: "GUO WEI\nPromotion Supervisor",
            },
            {
              url: require("../../../assets/team/te-7.png"),
              name: "张祥\n乐务主管",
              enName: "ZHANG XIANG\nStage Supervisor",
            },
          ],
        },
        {
          title1: "PERFORMANCE & MARKETING",
          title2: "DEPARTMENT",
          title3: "演出营销部",
          width: "50%",
          personList: [
            {
              url: require("../../../assets/team/te-16.png"),
              name: "叶秦阳 | 主任(兼)",
              enName: "YE QINYANG\nDirector(concurrently)",
            },
            // {
            //   url: require("../../../assets/team/te-18.png"),
            //   name: "谭苏亚 | 项目主管",
            //   enName: "TAN SUYA\nProject Manager",
            // },
            {
              url: require("../../../assets/team/te-17.png"),
              name: "戴文婷 | 项目主管",
              enName: "DAI WENTING\nProject Manager",
            },
            // {
            //   url: require("../../../assets/team/te-19.png"),
            //   name: "马王正  | 项目主管(兼)",
            //   enName: "MA WANGZHENG\n(concurrently)\nProject Manager",
            // },
          ],
        },
         {
          title1: "BRAND PROMOTION",
          title2: "DEPARTMENT",
          title3: "品牌宣传部",
          width: "33%",
          personList: [
             {
              url: require("../../../assets/team/te-16.png"),
              name: "叶秦阳 | 主任",
              enName: "YE QINYANG\nDirector",
            },
            {
              url: require("../../../assets/team/te-28.png"),
              name: "徐馨儿 | 副主任",
              enName: "XU XINER\nDeputy Director",
            },
            {
              url: require("../../../assets/team/te-40.png"),
              name: "沈轩 | 项目主管",
              enName: "SHEN XUANG\nProject Manage",
            },
          ],
        },
         {
          title1: "DEPARTMENT OF EDUCATION &",
          title2: " TUTORINGSERVICES",
          title3: "社会辅导部",
          width: "33%",
          personList: [
            {
              url: require("../../../assets/team/te-29.png"),
              name: "时玉莹 | 常务副主任",
              enName: "SHI YUYING\nExecutive Deputy Director",
            },
             {
              url: require("../../../assets/team/te-13.png"),
              name: "李昕瞳 | 教员",
              enName: "LI XINTONG\nInstructor",
            },
            {
              url: require("../../../assets/team/te-15.png"),
              name: "王家宝 | 教员",
              enName: "WANG JIABAO\nInstructor",
            },
            {
              url: require("../../../assets/team/te-32.png"),
              name: "许旭 | 教员",
              enName: "XU XU\nInstructor",
            },
            {
              url: require("../../../assets/team/te-36.png"),
              name: "许晨煜  | 教员",
              enName: "XU CHENYU\nInstructor",
            },
            {
              url: require("../../../assets/team/te-37.png"),
              name: "张宇航  | 教员",
              enName: "ZHANG YUHANGU\nInstructor",
            },
             {
              url: require("../../../assets/team/te-38.png"),
              name: "李怡萱 | 教员",
              enName: "LI YIXUAN\nInstructor",
            },
          ],
        },
        {
          title1: "ORGANIZATION & PERSONNEL",
          title2: "DEPARTMENT",
          title3: "组织人事部",
          width: "100%",
          personList: [
            // {
            //   url: require("../../../assets/team/te-9.png"),
            //   name: "周勇 | 主任(兼)",
            //   enName: "ZHOU YONG\n(concurrently) Director",
            // },
            {
              url: require("../../../assets/team/te-12.png"),
              name: "府靓娴 | 人事主管",
              enName: "FU JINGXIAN\nPersonnel Manager",
            },
          ],
        },
        {
          title1: "FINANCE & AUDIT",
          title2: "DEPARTMENT",
          title3: "财务审计部",
          width: "50%",
          personList: [
            {
              url: require("../../../assets/team/te-11.png"),
              name: "陈金洋 | 主任",
              enName: "Chen Jinyang\nDirector",
            },
            {
              url: require("../../../assets/team/te-33.png"),
              name: "肖蓉 | 副主任",
              enName: "XIAO RONG\nDeputy Director",
            },
          ],
        },
        {
          title1: "SUZHOU CHINESE ORCHESTRA",
          title2: "CONCERT HALL",
          title3: "苏州民族管弦乐团音乐厅",
          width: "33%",
          personList: [
             {
              url: require("../../../assets/team/te-16.png"),
              name: "叶秦阳 | 经理",
              enName: "YE QINYANG\nManager",
            },
            {
              url: require("../../../assets/team/te-19.png"),
              name: "马王正 | 执行经理",
              enName: "MA WANGZHENG\nExecutive Manager",
            },
            {
              url: require("../../../assets/team/te-20.png"),
              name: "陆梦洁 | 项目主管",
              enName: "LU MENGJIE\nProject Manager",
            },
            {
              url: require("../../../assets/team/te-21.png"),
              name: "陆晓旻 | 项目主管",
              enName: "LU XIAOMIN\nProject Manager",
            },
             {
              url: require("../../../assets/team/te-24.png"),
              name: "赵晋鹏 | 项目主管",
              enName: "ZHAO JINPENG\nProject Manager",
            },
            {
              url: require("../../../assets/team/te-34.png"),
              name: "祝孝军\n灯光师",
              enName: "ZHU XIAOJUN\nLighting Engineer",
            },
            {
              url: require("../../../assets/team/te-39.png"),
              name: "濮知我 | 项目主管",
              enName: "PU ZHIWO\nProject Manager",
            },
          ],
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.team {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  .team-title {
    .team-title-p1 {
      color: #333333;
      text-align: center;
      font-family: Playfair Display SC;
      letter-spacing: 1px;
    }
    .team-title-hz {
      width: 100%;
      height: 32px;
      background: linear-gradient(
        90deg,
        rgba(217, 221, 226, 0.2) 20%,
        #d9dde2 50%,
        rgba(217, 221, 226, 0.2) 80%
      );
      // &::after {
      //   content: "";
      //   display: block;
      //   width: 34px;
      //   height: 2px;
      //   background-color: #b9996b;
      //   position: relative;
      //   left: calc(50% - 17px);
      // }
    }
    .team-title-hz1 {
      width: 100%;
      height: 32px;
      line-height: 32px;
      background: linear-gradient(
        90deg,
        rgba(217, 221, 226, 0.2) 20%,
        #d9dde2 50%,
        rgba(217, 221, 226, 0.2) 80%
      );
    }
    .team-title-p2 {
      text-align: center;
      color: #333333;
      font-family: Source Han Sans CN;
      position: relative;
      top: -10px;
    }
  }
  .team-conter {
    text-align: center;
    white-space: pre-wrap;
    .team-img {
      width: 96px;
      height: 96px;
    }
  }
}
</style>

<style lang="scss">
.management {
  img {
    max-width: 100%;
  }
}
</style>

